@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Lato', 'Roboto', sans-serif !important;
}

.site-page {
  min-height: 100vh !important;
}

/* ------------ Header Section ---------- */

.navbar {
  padding: 15px 0 !important;
  background-color: #fff;
  /* box-shadow: 0px 15px 10px -15px #999; */
  /* border-bottom: 1px solid gray; */
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 15px;
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #44A726;
  font-weight: 700px;
}

.nav-height {
  height: 4.5rem;
}

/* .nav-item .active {
  color: #68ee40 !important;
} */


.logo_img {
  width: 75px;
}

.mobile_icons {
  display: flex;
}

.mobile_view {
  display: none;
}

.notify_img {
  width: 22px;
}

.cart_img {
  width: 27px;
}

.navbar-toggler-icon {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.navbar-light .navbar-toggler {
  border-color: #fff;
}

.navbar-toggler {
  padding: 0.25rem 0.6rem !important;
  margin-left: 10px !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
  outline: none !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  opacity: 0.9;
  background-color: #fff;
}

#mobile-floating-footer {
  display: none;
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding: 5px 0;
  }

  .navbar-nav {
    padding-top: 60px;
  }

  .mobile_icons {
    margin-left: auto;
  }

  .mobile_view {
    display: block;
  }

  .notify_img {
    width: 20px;
  }

  .cart_img {
    width: 25px;
  }

  .desktop_view {
    display: none;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 5px;
    color: #000;
    font-weight: 500;
  }

  .mobile_icons .nav-link {
    padding: 0 10px;
  }

  .logo_img {
    width: 65px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .mobile_icons {
    margin-left: auto;
  }

  .mobile_view {
    display: block;
  }

  .desktop_view {
    display: none;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 5px;
    color: #000;
    font-weight: 500;
  }

}

/* ---------- Download Our App ---------- */
#app {
  padding: 60px 0;
  /* background: rgb(2,0,36); */
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(10,77,105,1) 22%, rgba(14,104,141,1) 100%); */
  /* background: #0E688D; */
  /* background : linear-gradient(180deg, #ebfbff 23%, #fff 32%); */
}

.app_heading {
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  /* color: #68ee40; */
}

.app_text {
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
  font-weight: 500;
  /* color: #fff; */
}

.download_img {
  display: block;
  margin: auto;
  width: 200px;
  margin-top: 30px;
  background-color: #000;
}

.app_img {
  height: 480px;
}

.category-description-logo {
  height: 400px;
}


@media only screen and (max-width: 600px) {
  .app_heading {
    font-size: 32px;
    font-weight: 700;
  }

  .app_text {
    font-size: 16px;
  }

  .download_img {
    width: 150px;
    background-color: #000;
  }

  .app_img {
    height: 320px;
    margin-top: 30px;
  }

  .footer-cities {
    display: none;
  }

  .footer_col_services {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #mobile-floating-footer {
    display: block;
  }

  .category-info {
    display: none !important;
  }

  .category-description-logo {
    height: 150px;
    width: 150px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .app_heading {
    font-size: 36px;
  }

  .download_img {
    width: 180px;
  }

  .app_img {
    height: 350px;
    margin-top: 30px;
  }
}

/* FAQ */
#faq {
  min-height: 600px;
  font-size: 14px !important;
  /* background: linear-gradient(-180deg, #D6E6D2 -32%, rgba(0, 0, 0, 0) 26%) */
}

.faqheading {
  font-size: 14px !important;
  /* color:#44A726 !important; */
  font-weight: 500;
}


#accordionFaq>.accordion-button:not(.collapsed) {
  color: none;
}

.accordian-item {
  border: none;
}

.accordion-button.collapsed {
  /* background-color: #ebfbff; */
}

/*  */
/* ------------- Footer Section ------------- */
#footer {
  background-color: #3202d7;
  filter: saturate(20%);
  padding: 40px 0 20px 0;
}

.footer_heading {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer_text {
  font-size: 16px;
  color: #fff;
  opacity: 80%;
}

.footer_icon {
  font-size: 18px;
  color: #cccccc;
  margin-right: 20px;
}

.footer_link {
  text-decoration: none;
  line-height: 30px;
}

.footer_link:hover {
  color: #fff;
  opacity: 38%;

}

.footer_hr {
  color: #8a8d94;
}

.bottom_footer {
  color: #cccccc;
  font-size: 14px;
}

.bottom_footer a {
  color: #cccccc;
  text-decoration: none;
}

.bottom_footer a:hover {
  color: #fff;
  opacity: 38%;
}

.social_icon {
  width: 35px;
  margin: 0 10px;
}

.footer_col {
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  #footer {
    padding: 40px 0 20px 10px;
  }

  .footer_col {
    justify-content: start;
  }
}

.site-color {
  color: #0E688D !important;
}

/* Floating footer */
#mobile-floating-footer {
  background-color: #f7f5f5 !important;
}

.fas {
  font-size: 25px !important;
  color: #9e9e9e;
}

.footer-icon {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.footer-icon>.fas {
  padding-bottom: 10px;
}

.footer-icon-active {
  color: #37AB25;
}

/*  */



/* ------------ Login Section ----------- */
#login {
  padding: 120px 0 !important;
  background-color: #0E688D;
}

.login_container {
  background-color: #fff !important;
  padding: 3rem 6rem !important;
  border-radius: 10px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  box-shadow: 0px 0px 15px 0px #888888 !important;
  width: 1000px !important;
}

.login_heading {
  font-size: 24px;
  font-weight: 600;
}

.login_text {
  font-size: 16px;
}

.login_img {
  width: 400px;
}

.login_col {
  display: flow-root !important;
  margin: auto !important;
  padding: 0 4rem !important;
}

.input_field {
  border: none !important;
  border-bottom: 2px solid #0E688D !important;
  border-radius: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #0E688D !important;
}

.buttons {
  background-color: #0E688D;
  color: #fff;
  /* padding: 4px 12px 6px 12px !important; */

}

.cate_buttons {
  background-color: #0E688D !important;
  color: #fff !important;
  text-decoration: none;
  padding: 0px 10px 4px 10px;
  border-radius: 3px;
  font-size: 23px;
}

.buttons:hover {
  color: #fff !important;
  /* background-color: #44A726 !important; */
  transform: scale(1.025) !important;
}

.res_otp {
  font-size: 14px;
  color: #0E688D;
  text-decoration: none
}

.res_otp:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  #login {
    padding: 120px 0px !important;
  }

  .login_heading {
    font-size: 24px;
  }

  #login_img_sec {
    display: none !important;
  }

  .login_container {
    padding: 2rem 1rem !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 300px !important;
    border-radius: 5px !important;
  }

  .login_col {
    display: flow-root !important;
    margin: auto !important;
    padding: 0 2rem !important;
    /* margin-top: 30px !important; */
  }

  .login_text {
    font-size: 14px !important;
  }

}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  #login {
    padding: 200px 0px;
  }

  .login_img {
    width: 280px;
  }

  .login_col {
    display: flow-root;
    margin: auto;
    padding: 0 1rem 0 4rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #login {
    padding: 260px 0px;
  }
}


.loginbtn {
  display: inline;
  border: none;
  border-radius: 0 !important;
  background: #44A726 !important;
  color: #fff !important;
  width: 256px;
  margin-top: 2em;
  height: 3em;
}

.sitebtn {
  display: inline;
  border: none;
  border-radius: 0 !important;
  background: #44A726 !important;
  color: #fff !important;
  padding: 1em !important;
}

.sitebtn2 {
  display: inline;
  border: none;
  border-radius: 0 !important;
  background-color: #0E688D !important;
  color: #fff !important;
}

.sitebtn2-danger {
  display: inline;
  border: none;
  border-radius: 0 !important;
  background-color: #dc3545 !important;
  color: #fff !important;
}

.loginInput {
  font-size: 1.5em !important;
  padding: 0.5em !important;
  text-align: center;
}

.modal .modal-dialog-aside {
  width: 500px;
  max-width: 80%;
  height: 100%;
  margin: 0;
  transform: translate(0);
  transition: transform .2s;
}

.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 0;
  border-radius: 0;
}

.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto
}

.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}

.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}

.modal.show .modal-dialog-aside {
  transform: translateX(0);
}

.loginCloseBtn {
  background: none;
  color: #b1b9b9;
  font-weight: 500 !important;
  font-size: 1.2em !important;
}

.termsLink {
  font-size: 12px !important;
  font-weight: 700 !important;
}

/* ------------- Profile Section --------------- */
#profile {
  padding: 50px 0;
}

.profile_img {
  width: 80px;
  border-radius: 100px;
  border: 1px solid #631F7F;
}


/* ------------ Services Section (Home) ----------- */
#services {
  padding: 30px 0px;
}

.service_div {
  position: relative;
  top: -148px;
  background: #fff;
  border-radius: 8px;
  /* border: 0; */
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 8px 26px 0px;
}

.serviceImgFixed {
  max-height: 300px;
  height: 250px !important;
  width: auto;
}

.heading {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: #44A726;
}


.heading1 {
  font-size: 3rem;
  font-weight: 800;
  font-family: "Lato";
  line-height: 2em;
}

.sub_heading {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.service_col {
  margin-top: 30px;
}

.service_card {
  border: 2px solid #d6e6d2;
  border-radius: 12px;
  padding: .8rem;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 0px 0px 0px;
  /* background-color: #e5f4e1; */
}

.service_card:hover {
  background-color: #e5f4e1;
}

.service_img_sec {
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  flex-direction: row;
}

.card-text p {
  margin: 0px !important;
}

.service_img {
  /* max-width: 60px;
  max-height: 60px; */
  width: 100%;
  height: 100%;
}

.service_title {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
  color: #37AB25
}

.service_card_link {
  text-decoration: none;
}


/* .service_card_link:hover .service_title {
    color: #44A726;
} */

.carousel-inner img {
  height: 260px;
}

.serviceInfoTxtInfo {
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  #services {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .heading {
    font-size: 26px;
    font-weight: 600;
  }

  .service_card {
    border-radius: 5px;
    padding: 0.5rem;
  }

  .service_img_sec {
    /* width: 130px; */
    /* width: fit-content; */
  }

  .service_title {
    font-size: 12px;
    margin-top: 15px;
    font-weight: 500;
  }

  .carousel-inner img {
    height: 180px;
  }

  .serchText {
    font-size: 21px !important;
    line-height: 1.4em !important;
  }

  .search_input {
    height: 3em !important;
  }

  .search-suggesion {
    left: 10% !important;
    width: 80% !important;
  }

  .search_container {
    margin-bottom: 0px;
  }

  .serviceInfoTxt {
    text-align: center;
  }

  .serviceInfoTxtInfo {
    text-align: justify;
    padding: 15px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .service_img_sec {
    /* width: 180px;
        height: 130px; */
    /* width: fit-content;
    height: fit-content; */
  }

  .service_title {
    font-size: 18px;
    margin-top: 15px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .service_img_sec {
    /* width: 220px;
    height: 160px; */
  }

  .service_img_sec {
    /* width: 180px;
      height: 130px; */
    /* width: fit-content;
    height: fit-content; */
  }
}

/* ------------- Testimonial Section ------------ */
#testimonial {
  padding: 40px 0;
  /* background-color: #ebfbff; */
}

.testimonial_card {
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 10px;
}

.testmonial_img_sec {
  width: 80px;
  height: 80px;
}

.testmonial_img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.user_name {
  font-size: 14px;
  text-align: center;
  color: #000;
  padding: 5px 0;
  font-weight: 500;
}

.testimonial_text {
  font-size: 14px;
  padding: 10px 0;
}

.testimonial_icon {
  font-size: 12px;
  margin: 15px 2px 0 2px;
  color: #0E688D;
}

.padding_sec {
  padding: 0 10px 0 20px;
}

.load {
  text-align: center;
  font-size: 16px;
  color: #44A726;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
}

#hide_show,
#load_2 {
  display: none;
}

/* Myorders */
.order-box {
  padding: 15px;
  border: 1px solid #0E688D;
  border-radius: 10px
}

.paid-order-stamp {
  background: center;
  background: url("/src/components/user/images/paidlogo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 95px;
}

.job-code-bg {
  padding: 10px;
  font-weight: 500;
  background-color: #0E688D;
  color: #fff !important;
}


/* ------------ Contact Us Section ----------- */
#contact {
  padding: 40px 0;
  background-color: #F8F8F3;
  background:
    linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.1)),
    url(https://media.istockphoto.com/id/938430346/photo/managing-the-days-inquiries.jpg?s=612x612&w=0&k=20&c=TOwWPyMKYSAmSI8qGTYt8frCdQYuEwMiq-LlUvEUskc=);
  background-size: cover;
  background-repeat: no-repeat;

}

.contact_icon {
  font-size: 24px;
  color: #0E688D;
}

.contact_text {
  font-size: 15px;
  font-weight: 600;
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .contact_text {
    font-size: 15px;
  }

  .contact_icon {
    font-size: 22px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .contact_text {
    font-size: 15px;
  }

  .contact_icon {
    font-size: 22px;
  }
}

/* -------------------- Address Section -------------- */

.address_dots {
  /* display: block !important; */
  float: right !important;
  /* width: 35px !important;
  margin-top: -10px ;
  margin-right: -5px; */
}

.address_dots_content {
  min-width: 60px;
  margin-left: -30px;
}

.address_dots_content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 12px;
}

.dropbtn {
  color: balck;
  padding: 16px 12px 5px 12px;
  font-size: 18px;
  border: none;
  font-weight: 400;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.address_dots_content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 12px;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.address_dots_content {
  min-width: 60px;
  margin-left: -30px;
}

.address_text {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

/* #add_address {
  display: none;
} */
/* ------------ About Us Section --------- */
#about {
  padding: 40px 0;
}

.about_img {
  display: block;
  margin: auto;
  width: 100%;
}

.about_heading {
  font-size: 34px;
  color: #44A726;
  font-weight: 700;
  border-bottom: 2px solid #44A726;
  margin-bottom: 20px;
}

.about_text {
  font-size: 16px;
  text-align: justify;
  color: gray;
}

hr {
  margin-top: 30px !important;
}

@media only screen and (max-width: 600px) {
  .about_heading {
    font-size: 28px;
  }
}


/* ----------------- Notification Section -------------- */
.notification_title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.notification_text {
  font-size: 16px;
  color: gray;
  margin-bottom: 15px;
  width: 100%;
}

.notification_date {
  font-size: 14px;
  display: block;
  color: gray;
  /* float: right; */
}

/* ------------ Cart Section ---------- */
#cart {
  padding: 60px 0;
}

.cartTotalRow {
  font-weight: 600;
  font-size: 20px;
  margin: 40px;
  /* border-top: 1px solid #ccc; */
  /* border-bottom: 1px solid #ccc; */
}

.service_img {
  /* width: 80px;
  height: 60px; */
}

.total_amount {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.coupon_discount {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 10px 0;
}

.coupon_discount span {
  font-size: 16px;
  font-weight: 400;
  margin-left: 60px;
  font-weight: 600;
}

.total_amount span {
  font-size: 16px;
  font-weight: 400;
  margin-left: 60px;
  font-weight: 600;
}

.remove_item {
  /* background-color: red; */
  border-radius: 100px;
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.qty {
  width: 30px !important;
  min-width: 30px;
  height: 30px;
  text-align: center;
  padding: 0;
  background-color: #44A726;
  border: none;
  color: #fff;
}

input.qtyplus {
  width: 30px !important;
  min-width: 30px;
  height: 30px;
  background-color: #44A726;
  border: none;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

input.qty-search:disabled {
  background-color: rgb(143, 143, 143);
}

input.qty-search:disabled {
  background-color: rgb(143, 143, 143);
}

input.qtyminus {
  width: 30px !important;
  min-width: 30px;
  height: 30px;
  background-color: #44A726;
  border: none;
  font-weight: 600;
  color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.coupon_field {
  width: 20% !important;
  margin-top: 20px;
}

/* .input-group-text {
  padding: 0 !important;
} */

.input-group-text button {
  background-color: #0E688D;
  color: #fff;
  padding: 5px 8px;
}

.input-group-text button:hover {
  color: #fff;
  background-color: #44A726
}

.unique {
  display: none !important;
}

.item-img {
  height: 60px;
  width: auto;
  border-radius: 50%;
}

.card-title {
  font-size: 14px;
  color: #0E688D !important;
  padding-bottom: 1em;
}

.cart-button-div {
  display: flex;
  justify-content: space-evenly;
  vertical-align: middle;
  align-items: baseline;
}


@media only screen and (max-width: 600px) {
  .coupon_field {
    width: 60% !important;
  }

  .unique {
    display: block !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .coupon_field {
    width: 30% !important;
  }
}


/* Service Categories Section */
.cate_card {
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 10%);
  margin-top: 25px;
  height: 200px;
  min-height: 250px;
  /* box-shadow: 1px 3px 8px #888888; */
  /* border: 0px !important; */
  /* border-color: #0e688d !important; */
  border-radius: 15px !important;
  /* margin-bottom: 50px; */
}

.cate_head {
  background-color: #0E688D;
  color: white;
  padding: 15px;
  text-align: center;
  position: sticky;
  top: 55px;
  z-index: 2;
}

.addbtn {
  /* display: none !important; */
  height: 2em;
  width: 100%;
  border-radius: 0px !important;
  font-size: 1em !important;
  text-transform: uppercase;
  padding: 0px !important;
  border: 1px solid !important;
}

.addBtn2 {
  font-weight: 600 !important;
  border: 1px solid;
  border-radius: 30px !important;
  width: 40px;
  height: 40px;
  min-width: 40px !important;
  margin: 5px !important;
  background-color: #3c758b !important;
  color: #fff !important;
}

.item-amount {
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 4em;
}

.item-cart-footer {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  padding-bottom: 20px;
}

.card {
  /* border: 1px solid #0e688d !important; */
}

.card-body {
  /* white-space: nowrap; */
}

.accordion-button:not(.collapsed) {
  background-color: #e5f4e1 !important;
}


@media only screen and (max-width: 600px) {
  .cate_card {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  #login {
    padding: 100px 0px;
  }

  /* #mobile-floating-footer {
    display: none;
  } */
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #login {
    padding: 150px 0px;
  }
}


/* 404 Page Design */

#main66 {
  display: table;
  width: 100%;
  margin: auto;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
  color: #888;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }

  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

/* My Cart */
.cart_container {
  background-color: #fff !important;
  padding: 1.5rem 3rem !important;
  border-radius: 10px !important;
  /* position: absolute !important; */
  /* top: 50% !important;
  left: 50%; */
  /* transform: translate(-50%, -50%) !important; */
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%) !important;
  /* width: 1000px !important; */
}

#cart_sect {
  padding: 40px 0 !important;
  background-color: #f8f8f3;
}

.checkoutBtn {
  border: none;
  border-radius: 0 !important;
  background-color: #0E688D !important;
  color: #fff !important;
  /* width: 256px;
  height: 3em; */
  padding: 1em;
}

.clearCartBtn {
  border: none;
  border-radius: 0 !important;
  background: #f64a37 !important;
  color: #fff !important;
  /* width: 256px;
  height: 3em; */
  padding: 1em;
}

/* For ZIpcode Error Message */
.error input[type="text"] {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.mt-100 {
  margin-top: 100px;
}

.why-us {
  padding-top: 2em;
  height: 150px;
  min-height: 150px;
}

.why-us-container {
  /* padding-top: 50px; */
  /* background: #f3f3f3; */
  margin-top: 50px;
  padding-bottom: 50px;
  min-height: 500px;
}

/* Profile Page */

#prof {
  padding: 20px 0 !important;
  /* background-color: #F8F8F3; */

}

.prof_container {
  background-color: #fff;
  padding: 3rem 6rem !important;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px #888888;
  margin-top: 40px;
}

.address_container {
  background-color: #fff;
  padding: 20px !important;
  /* border-radius: 10px;
  box-shadow: 0px 0px 8px 0px #888888; */
}

.address_container_div {
  margin: 5px;
  border: 1px solid #d6e6d2;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 1px 3px 0px;
}

.office_address {
  padding: 50px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  #prof {
    padding: 40px 20px !important;
  }

  .prof_container {
    padding: 2rem 0.8rem !important;

  }

  .office_address {
    padding: 25px;
  }

  .contact_form_row {
    margin: 10px !important;
  }

  .loginbtn {
    width: 150px !important;
    height: 2.5em !important;
  }

  .office_heading {
    text-align: center;
  }

  .why-us {
    min-height: auto !important;
  }
}

/* FOR CHOOSE ADDRESS */
.Plan_card1 {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
  width: 100%;
}

.Plan_card1:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: var(--radio-size);
  }

  .radio::after {
    border: var(--radio-border-width) solid #fff;
    border-top: 0;
    border-left: 0;
    content: "";
    display: block;
    height: 0.75rem;
    left: 25%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    width: 0.375rem;
  }

  /* .radio:checked {
    background: var(--color-green);
    border-color: var(--color-green);
  } */

  .Plan_card1:hover .radio {
    border-color: var(--color-dark-gray);
  }

  .Plan_card1:hover .radio:checked {
    border-color: var(--color-green);
  }
}

.plan-details1 {
  /* border: var(--radio-border-width) solid var(--color-gray); */
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
  height: -webkit-fill-available;
}

/* .Plan_card1:hover .plan-details1 {
  border-color: var(--color-dark-gray);
} */

.radio:checked~.plan-details1 {
  /* border-color: #0e688d; */
  border: 2px solid #0E688D;
  /* border-radius: 10px; */
  background-color: #1d5530 !important;
}

.radio:focus~.plan-details1 {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.plan-details1 {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled~.plan-details1 .plan-type {
  color: var(--color-dark-gray);
}

.Plan_card1:hover .radio:disabled~.plan-details1 {
  /* border-color: var(--color-gray); */
  box-shadow: none;
}

.Plan_card1:hover .radio:disabled {
  /* border-color: var(--color-gray); */
}

.plan-type {
  color: var(--color-green);
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 1.5rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.plan_list {
  font-size: 16px;
  margin: 3px 0px;
}

/* MOAL */
.cookiesContent {
  /* width: 320px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  /* padding: 30px 30px 70px; */
}

.cookiesContent button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}

.cookiesContent img {
  width: 82px;
  margin-bottom: 15px;
}

.cookiesContent p {
  margin-bottom: 40px;
  font-size: 18px;
}

.cookiesContent button.accept {
  background-color: #0E688D;
  border: none;
  border-radius: 5px;
  /* width: 200px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  /* box-shadow: 0px 6px 18px -5px #0E688D; */
}


/* ------------- Date Time Section --------- */
/* :root {
  --card-line-height: 1.2em;
  --card-padding: 10px;
  --card-radius: 0.5em;
  --color-green: #000;
  --color-gray: rgb(158, 158, 158);
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 1px;
  --radio-size: 1.5em;
} */
.plan-details {
  /* border: var(--radio-border-width) solid var(--color-gray); */
  background-color: #0E688D;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
  width: 80px;
  height: 80px;
  margin: 0px 10px;
}

.radio:checked~.plan-details {
  /* border: 2px solid var(--color-green); */
  background-color: #44A726;
}

.day {
  font-size: 20px;
  text-align: center;
  padding: 2px 0;
  color: #fff;
}

.date {
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.time_card {
  border: 1px solid gray;
  padding: 15px 80px;
  border-radius: 5px;
}

.radio:checked~.time_card {
  border: 2px solid #0E688D;
}

.time_text {
  font-size: 16px;
}

.extra {
  position: absolute;
  margin: 17px 0 0 75px;
}

.radio:disabled~.time_card {
  /* border: 2px solid #0E688D; */
  background-color: rgb(172, 172, 172);
}

.thnaks_imgsss {
  width: 500px;
  margin-top: 40px;
}

@media only screen and (max-width: 600px) {
  .time_card {
    border: 1px solid gray;

    padding: 15px 30px;
    border-radius: 5px;
  }

  .extra {
    margin: 17px 0 0 22px;
  }

  .thnaks_imgsss {
    width: 300px;
  }
}


/* FOR Thanku Page  */
.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
}

.thankyou-wrapper h1 {
  /* font: 100px Arial, Helvetica, sans-serif; */
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}

.thankyou-wrapper p {
  font-size: 26px;
  text-align: justify;
  color: #333333;
  padding: 5px 10px 10px;
  font-weight: 600;
}

.thankyou-wrapper a {
  /* font: px Arial,; */
  text-align: center;
  color: #ffffff;
  /* display: block; */
  text-decoration: none;
  /* width: 250px; */
  background: #349656;

  /* margin: 10px auto 0px; */
  padding: 8px 10px;
  /* border-bottom: 5px solid #F96700; */
  border-radius: 5px;
  margin-left: 5px;
}

.thankyou-wrapper a:hover {
  color: #ffffff;

  background: #1d5530;
}

#address_div {
  /* padding: 20px 0 !important; */
  min-height: 100vh;
}

.address_row {
  margin-top: 40px;
}

.address_card {
  display: block;
  /* background-color: #f1f1f1; */
  border-radius: 10px;
  border: 1px solid #0e688d;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
  padding: 20px 15px;
  margin-bottom: 20px;
}

.address_card>a {
  text-decoration: none;
  color: #4e4b4b;
}

.address_name {
  font-size: 24px;
}

.address {
  font-size: 16px;
  margin-top: 10px;
}

.address_mobile {
  font-size: 16px;
}

.address_dots {
  display: block;
  float: right;
  /* width: 35px; */
}

.address_dots_content {
  min-width: 60px;
  margin-left: -30px;
}

.address_dots_content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 12px;
}

.order_col {
  margin-top: 30px;
}

.order_details {
  /* font-weight: 600; */
  line-height: 35px !important;
}

.order_details span {
  font-size: 18px;
  text-align: right;
  /* font-weight: 600; */
}

.delivered {
  display: block;
  float: right;
  background-color: #349656;
  color: #fff;
  padding: 3px 5px;
  font-size: 16px !important;
  margin-top: -25px;
  margin-right: 5px;
  border-radius: 5px;
}

/* ---------------- My Order Section --------------- */
#my_order {
  padding: 50px 0;
}

.table_row {
  margin-top: 50px;
}

.my_order_table {
  background-color: #fff;
  width: 100%;
}

.my_order_thead {
  background-color: #0E688D;
  color: #fff;
}

th {
  font-weight: normal;
}

td {
  vertical-align: middle;
}

.table> :not(caption)>*>* {
  padding: 1rem 1rem;
}

.my_order_img {
  width: 80px !important;
  height: 60px !important;
}

.my_order_name {
  font-size: 20px;
}

.my_order_desc {
  font-size: 14px;
}

.serv_img {
  width: 100%;
  height: 400px;
}

.mob_viw {
  text-align: right;
}


/* FOR BG */


/* -------------------- Header Section ----------- */
/* .logo_img {
  width: 170px;
}

header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  z-index: 100;
}
.bcg {
  background: #fff;
}
.navbar-toggler {
  font-size: 1rem !important;
  line-height: 1 !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 0.25rem !important;
  transition: box-shadow 0.15s ease-in-out !important;
  padding: 3px 5px !important;
}
.justify-content-end {
  background-color: #fff !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
  outline: none !important;
}
.dropbtn {
  color: balck;
  padding: 16px;
  font-size: 18px;
  border: none;
  font-weight: 400;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 600px) {
  .bcg {
    background: #fff;
  }
}

.marg_top {
  margin-top: 100px;
}

.navbar-expand-lg .navbar-nav .nav-link.active {
  color: #68ee40;
  font-weight: 600;
}
.nav-item .nav-link {
  font-size: 16px !important;
  font-weight: 600 !important;
} */
.marg_top {
  margin-top: 59px;
}

.locationDropdownDiv {
  left: 10%;
  /* background-color : #44A726; */
}


.nav-item .nav-link {
  font-size: 18px;
  font-weight: 400;
  /* color: #0E688D !important; */
  color: 0 8px 26px 0 rgba(0, 0, 0, 0.16) !important;
}

/* .active{
  color:red;
 } */

.abs_logo {
  position: absolute;
  top: 8px;
  border: 4px solid white;
  border-radius: 100px;
}

.carau_div {
  /* background-color: #0E688D; */
  /* background: rgb(197,219,228); */
  /* background: linear-gradient(90deg, rgba(197,219,228,1) 0%, rgba(255,255,255,1) 0%, rgba(73,169,113,1) 0%, rgba(14,104,141,1) 53%); */
  /* height: 260px; */
  height: 400px;
  background: #fff;
}

.search_container {
  /* margin-top: 80px; */
  /* margin-bottom: 100px !important; */
}

.search {
  width: 50% !important;
  margin: auto;
  border: 1px solid #34a1d4;
  border-radius: 25px;
}

.search_input {
  border: none !important;
  /* border-top-left-radius: 100px !important; */
  /* border-bottom-left-radius: 100px !important; */
  padding: 10px 25px !important;
  height: 4rem;
}

.search_input:focus {
  box-shadow: none !important;
}

#basic-addon2 {
  margin-right: -12px;
  /* border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important; */
  width: 50px;
  background: #44A726;
}

.searchButton {
  justify-content: center;
  margin-right: 0px;
  width: 80px;
  max-width: 80px;
  background: #44A726;
}

.prevBtn {
  color: #0E688D !important;
  font-weight: 600 !important;
  border: 1px solid #0E688D !important;
}

.locationDropdownBtn {
  font-size: 14px !important;
  background-color: #fff !important;
  color: #000 !important;
}

#mobile_view {
  display: none;
}

#desktop-floating-footer {
  float: right;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
  .abs_logo {
    top: 20px;
  }

  .locationDropdownDiv {
    left: 20%;
  }

  .locationDropdownBtn {
    font-size: 14px;
  }

  .serv_img {
    height: auto;
  }

  #mobile_view {
    display: block;
  }

  .mob_viw {
    text-align: right;
  }

  .search {
    width: 80% !important;
    margin: auto;
  }

  .carau_div {
    height: 180px;
  }

  #desk_view {
    display: none;
  }

  #cart {
    padding: 0px;
  }

  .address_dots_content {
    margin-left: -64px;
  }

  .searchDiv {
    background-position: inherit !important;
    height: 300px !important;
    padding-top: 60px;
  }

}

/* FOR SEARCH */
/* For Search Card  */
.serch_div {
  /* border: 1px solid lightgray; */
  /* border-left: 1px solid lightgray; */
  width: 540px !important;
  padding: 5px;
  margin-left: 408px;
  background: #fff;
  /* border-radius: 5px; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.serch_div:hover {
  background-color: #00AB6C;
  color: white;
}

.suggestte {
  cursor: pointer;
  z-index: 100;
}

.marg_topss {
  margin-top: -80px;
}

.marg_notss {
  margin-top: 0px;
}

/*pincode*/
.pincode-input {
  font-size: 2em ! important;
  text-align: center;
  font-weight: bold !important;
  /* padding: 1em !important; */
}

.pincodemodal-header {
  justify-content: center !important;
  height: 100px;
  /* background-color: #abd19f; */
  border: 0px !important;
}

.pincodeCloseBtn {
  position: absolute;
  right: -6px;
  top: -11px;
  color: #fff !important;
  border: 1px solid !important;
  border-radius: 40px !important;
  height: 44px;
  width: 44px;
  background-color: black !important;
  overflow: hidden;
  display: flex !important;
  /* flex-direction: column; */
  justify-content: center;
  vertical-align: middle !important;
  padding-bottom: 5px !important;
  font-weight: 800 !important;
  font-size: 20px !important;
}

.pincodeBtn {
  border: 0px !important;
  border-radius: 0px !important;
  background-color: #44A726 !important;
  color: #fff !important;
}

.search-suggesion {
  position: absolute;
  z-index: 999;
  margin-top: 0.2em;
  left: 25%;
  width: 50%;
  max-height: 400px;
  overflow-y: hidden;
  border: 1px solid #d4d4d4;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 8px 26px 0px;
  border-radius: 10px;
}

.search-item {
  background-color: #fff;
  color: #000;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border: 1px solid #d4d4d4;
  border-top: 0px;
  text-align: left;
  padding: 1em;
}

.search-item:hover {
  background: #d7f3ce;
}

.searchDiv {
  height: 550px;
  background: linear-gradient(300deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url("/src/components/user/images/static/bg-seaarch.webp");
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.serchText {
  color: rgb(255, 255, 255);
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
}


/* Notification pop */
.notificationPop {
  position: absolute;
  top: 60px;
  left: -200px;
  display: inline-block;
  height: 300px;
  width: 450px;
  min-height: 200px;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  background-color: #ffff;
  border: 2px solid #efefef;
  border-radius: 0px 0px 10px 10px;
  padding: 30px;
}

.notification_container {
  background-color: #fff;
  padding: 20px !important;
  border: 1px solid #c6c0c0;
  word-wrap: break-word;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 1px 3px 0px;
}

.notification_container>.notification_text>p>img {
  max-height: 300px !important;
  max-width: 300px !important;
}

.notification_modal>.modal>.modal-dialog>.modal-content>.modal-body>p>img {
  max-height: 300px !important;
  max-width: 300px !important;
}

.notification_row {
  max-height: 600px;
  overflow-y: scroll;
  border-top: 1px solid #e3e3e3;
}

@media only screen and (max-width: 600px) {
  .serch_div {
    width: 330px !important;
    margin: auto;
  }

  .marg_topss {
    margin-top: -40px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .serch_div {
    width: 330px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .serch_div {
    width: 430px !important;
  }
}


/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: transparent;
  /* color: #FFF; */
  /* border-radius: 50px; */
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }

  #desktop-floating-footer {
    bottom: 66px;
  }

  .qty {
    min-width: 26px;
    height: 25px;
    text-align: center;
    padding: 0;
    color: #fff;
    border: none;
  }

  input.qtyplus {
    width: 22px;
    min-width: 22px;
    height: 25px;
  }

  input.qtyminus {
    width: 22px;
    min-width: 22px;
    height: 25px;
  }

}